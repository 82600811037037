import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ServiceCard from '../../components/ServiceCard';
import { SSectionH2Title, StyledContainer } from '../Layout/styles';
import Breadcrumbs from '../../components/Breadcrumbs';

const PaslaugosContainer = ({ data, isBreadcrumbVisible = false }) => {
    return (
        <StyledContainer fluid>
            <Row>
                {isBreadcrumbVisible && <Breadcrumbs title="Paslaugos" />}
                <SSectionH2Title>Paslaugos</SSectionH2Title>
                {data?.map((item, index) => {
                    const image = item.featuredImage
                        ? getImage(item.featuredImage.node.localFile)
                        : null;

                    return (
                        <Col key={index} xs={12} lg={4} className="p-0">
                            <ServiceCard
                                title={item.title}
                                description={item.paslaugos.trumpasAprasymas}
                                imageSrc={image}
                                imageAlt={item.title}
                                linkText="Sužinokite daugiau"
                                linkURL={`/paslaugos/${item.slug}`}
                                borderRight={data.length - 1 === index}
                            />
                        </Col>
                    );
                })}
            </Row>
        </StyledContainer>
    );
};

export default PaslaugosContainer;
