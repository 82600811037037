import { graphql } from 'gatsby';
import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import PaslaugosContainer from '../containers/PaslaugosContainer';

const Paslaugos = ({ data }) => {
    return (
        <>
            <PaslaugosContainer data={data.allWpPaslauga.nodes} isBreadcrumbVisible={true} />
        </>
    );
};

export default Paslaugos;

export const pageQuery = graphql`
    query {
        allWpPaslauga {
            nodes {
                id
                title
                seo {
                    canonical
                    metaDesc
                    focuskw
                    metaKeywords
                    title
                }
                paslaugos {
                    trumpasAprasymas
                }
                slug
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    width: 450
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
