import React from 'react';
import { SWrapper, SImage, STitle, SDescription, SLink, SDiv } from './styles';
import ArrowIcon from '../../images/svg/arrow.svg';

const ServiceCard = ({
    title,
    description,
    imageSrc,
    imageAlt,
    linkText,
    linkURL,
    ...props
}) => {
    return (
        <SWrapper {...props}>
            {/* <SDiv> */}
                <SImage image={imageSrc} alt={imageAlt} />
                <STitle>{title} </STitle>
                <SDescription>{description}</SDescription>
                <SLink to={linkURL}>
                    {linkText}
                    <ArrowIcon />
                </SLink>
            {/* </SDiv> */}
        </SWrapper>
    );
};

export default ServiceCard;
