import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    padding: 32px;
    border: 1px solid ${(props) => props.theme.color.grey_900};
    margin: 0 -1px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        border: 1px solid ${(props) => props.theme.color.grey_900};
        margin: -1px 0;
    }
`;

export const SImage = styled(GatsbyImage)``;
export const STitle = styled.h3`
    margin: 32px 0 24px 0;
`;
export const SDescription = styled.div`
    color: ${(props) => props.theme.color.grey_600};
    margin-bottom: 40px;
`;
export const SLink = styled(Link)`
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: ${(props) => props.theme.lineHeight.redular};
    color: ${(props) => props.theme.color.primary};

    svg {
        margin-left: 10px;
    }

    &:hover {
        color: ${(props) => props.theme.color.grey_900};

        svg > path{
            stroke: ${(props) => props.theme.color.grey_900};
        }
    }
`;
